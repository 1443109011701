@use "sass:list";
$success-color: #28a745;
$warning-color: #eca52b;
$info-color: #17a2b8;
$dark-color: #343a40;
$light-dark-color: #6c757d;
$buttons: 
dark $dark-color, 
lightdark $light-dark-color, 
success $success-color, 
warning $warning-color, 
info $info-color;
@mixin getButtons($index:1){ 
    @if $index <= length($buttons) {
        $name: nth(nth($buttons, $index),1);
        $color: nth(nth($buttons, $index),2);
        $colorHoverFocus: rgba($color, 75%/100.0%);
        $colorHoverBorderFocus: rgba($color, 10%/100.0%);
        $colorActive: darken($color, 10%);
    .ant-btn-#{$name}{
        color: #fff;
        background-color: $color;
        border-color: $color;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-#{$name}-disabled,
    .ant-btn-#{$name}.disabled,
    .ant-btn-#{$name}[disabled],
    .ant-btn-#{$name}-disabled:hover,
    .ant-btn-#{$name}.disabled:hover,
    .ant-btn-#{$name}[disabled]:hover,
    .ant-btn-#{$name}-disabled:focus,
    .ant-btn-#{$name}.disabled:focus,
    .ant-btn-#{$name}[disabled]:focus,
    .ant-btn-#{$name}-disabled:active,
    .ant-btn-#{$name}.disabled:active,
    .ant-btn-#{$name}[disabled]:active,
    .ant-btn-#{$name}-disabled.active,
    .ant-btn-#{$name}.disabled.active,
    .ant-btn-#{$name}[disabled].active {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .ant-btn-#{$name}:hover,
    .ant-btn-#{$name}:focus {
        color: #fff;
        background-color: $colorHoverFocus;
        border-color: $colorHoverBorderFocus;
    }
    .ant-btn-#{$name}:active,
    .ant-btn-#{$name}.active {
        color: #fff;
        background-color: $colorActive;
        border-color: $colorActive;
    }
    .ant-btn-background-ghost.ant-btn-#{$name} {
        color: $color;
        background: transparent;
        border-color: $color;
        text-shadow: none;
    }
    .ant-btn-background-ghost.ant-btn-#{$name}:hover, 
    .ant-btn-background-ghost.ant-btn-#{$name}:focus {
        color: $colorHoverFocus;
        background: transparent;
        border-color: $colorHoverFocus;
    }
    .ant-btn-background-ghost.ant-btn-#{$name}:active, 
    .ant-btn-background-ghost.ant-btn-#{$name}.active {
        color: $color;
        background: transparent;
        border-color: $colorActive;
    }
    @include getButtons($index + 1);
}
};

@include getButtons();

.disable-animation:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}