.login{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #2a2a72;
    background-color: #e7502e;
    background-image: linear-gradient(315deg,#e7502e 0%,#fad6cf 90%);

    &__logo{
        font-size: 60px;
        color: #333333;
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-box;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #fff;
        margin: 0 auto;
    }
    &__title{
        display: block;
        font-family: Poppins-Bold;
        font-size: 40px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 25px;
        padding-top: 25px;
    }
    &__content{
        background-color: #fff;
        padding: 50px 50px;
        border-radius: 20px;
        width: 500px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    }
}

.input__login{
    font-family: Poppins-Regular;
    font-size: 18px;
    color:#333333;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px 0 38px;
    border-bottom: 2px solid rgba(255,255,255,0.24);    
}