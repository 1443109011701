.mt-1 {
    margin-top: 5px;
}
.mb-1 {
    margin-bottom: 5px;
}
.ml-1 {
    margin-left: 5px;
}
.ml-2 {
    margin-left: 10px;
}
.mr-1 {
    margin-right: 5px;
}
.mr-2 {
    margin-right: 10px;
}
.mb-5 {
    margin-right: 20px;
}
.mt-2 {
    margin-top: 10px;
}
.mb-2 {
    margin-bottom: 10px;
}
.pb-1{
    padding-bottom: 5px;
}
.pb-2{
    padding-bottom: 10px;
}
.pb-3{
    padding-bottom: 15px;
}
.pt-1{
    padding-bottom: 5px;
}
.pt-2{
    padding-bottom: 10px;
}
.pt-3{
    padding-bottom: 15px;
}
.pl-1{
    padding-left: 5px;
}
.pl-2{
    padding-left: 10px;
}
.pl-3{
    padding-left: 15px;
}
.pr-1{
    padding-right: 5px;
}
.pr-2{
    padding-right: 10px;
}
.pr-3{
    padding-right: 15px;
}

.red_color{
    color:#f5222d
}
.blue-color{
    color:#1677ff
}