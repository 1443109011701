.costosproduccionitem  {
    .ant-descriptions-header{
        margin-bottom: 10px;
    }
    .ant-descriptions-view .ant-descriptions-item{
        padding-bottom: 0px;
    }
}
.DivCostosunitariosproduccion{
    .ant-descriptions-header{
        margin-bottom: 8px;
    }
}