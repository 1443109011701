body{
  margin: 2px;
}
.ant-layout-header{
  padding-inline: 0px;
  & .logo-vertical{
    height: 60px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.2);
  }
  & .btnToogleSider{
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #fff;

    &:hover{
      color: #1890ff;
    }
  }
  & .right-menu{
    float: right;
    & > button {
      color: #fff;
    }
    
  }
}
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
    padding: 24;
    margin: 0;
    max-height: 480;  
  }
  .site-layout-content {
    margin: 10px;  
  }
  .ant-layout-sider-children > .logo-vertical{
    height: 64px;
    line-height: 80px;
    // margin: 16px 24px 16px 0;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
  }
  .header-menu-right{
    justify-content: center;
    & > .ant-menu-item{
      padding-inline: 10px;
    }
  }