.pos__producto__header{
    padding: 10px;
    text-align: center;
}
.pos__producto__search{
    padding: 10px;
    text-align: center;
}
.pos__producto__list{
    padding: 10px;
    &__card{
        cursor: pointer;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    &:hover{
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
    }
    }
}
.pos__carrito{
    &__header{
        padding: 5px;
        >.ant-row .ant-form-item{
            margin: 0 0 10px;
        }
    }
    &__content{
        overflow-y: auto;
        >.ant-row .ant-form-item{
            margin: 0 0 5px;
        }
        &__list{
            background-color: #fff;
            border-radius: 10px 10px 0px 0px;
            > .ant-list-header{
padding: 15px;
font-size: 14px;
font-weight: bold;
            }
        }
    }
    &__footer{
        background-color: #fff;
        border-radius: 0px 0px 10px 10px;
        border-top: 1px solid rgba(5, 5, 5, 0.06);
        .ant-form-item-control-input-content > span{
            font-size: 20px;
        }
        .ant-input{
            font-weight: bold;
        }
        &__payment{
            >.ant-row .ant-form-item{
                margin: 0 0 10px;
            }
        }
        &__opciones{
            text-align: center;
            align-items: center;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
        }
        &__totals{
            .ant-input-number{
                input{
                    font-weight: bold;
                    font-size: 18px;
                }
                
            }
        }
    }

}
.pos__carrito__montos__item{
    padding-bottom: 4px !important;
    >div>span>div>div>.ant-form-item-explain{
        min-height: 2px;
    }
}

.poscarritocontent__item > div {
        > .ant-descriptions-item-content{
        font-size: 10px;
    }
}
.poscarritocontent__item > div > span > div > div >div.ant-form-item-explain{
            min-height: 10px;
        }

.pos__carrito__mediospago__list > div>div>ul>li.ant-list-item{
    padding: 2px 4px;
    font-size: 11px;    
}
.pos__carrito__mediospago__list__item>div>table>tbody>tr>td.ant-descriptions-item{
    padding-bottom: 1px;
}

.summary_order {
    .ant-input-number{
        input{
            font-weight: bold;
            font-size: 18px;
        }
        
    }
}